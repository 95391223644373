import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { retry } from '../utils/retry';

export type PortalProps = {
  children: React.ReactNode;
  id: string;
  selector?: string;
};
export function Portal<HTMLElement extends Element>({ children, id, selector }: PortalProps) {
  const [el, setEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    retry(
      () => {
        let el = document.getElementById(id) as unknown as HTMLElement | null;

        if (selector) {
          el = el?.querySelector(selector) as unknown as HTMLElement | null;
        }

        if (el) {
          setEl(el);
        } else {
          throw new Error('portal el is not defined');
        }
      },
      { attempts: 10, millis: 300, failSilently: true }
    )();
  }, [id, selector]);

  return <>{el ? createPortal(<>{children}</>, el) : null}</>;
}

'use client';

import { Box } from 'ui/components/box';
import { CONSTANTS } from '../../constants';
import NextImage from 'next/image';
import { Montserrat } from 'next/font/google';

const montserrat = Montserrat({ subsets: ['latin'], weight: '600' });

export function Logotype({ isLight = false }: { isLight?: boolean }) {
  return (
    <Box css={{ alignItems: 'center' }} layout='flexRow'>
      <NextImage
        alt='logotype'
        height={62}
        priority
        src={isLight ? CONSTANTS.ASSETS.images.logos.logo_light_png : CONSTANTS.ASSETS.images.logos.logo_dark_png}
        width={55}
      />
      <Box
        className={montserrat.className}
        css={{
          color: isLight ? 'esa.on_primary' : 'esa.primary',
          fontSize: [18, 24],
          letterSpacing: '-1px',
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        THERAPY ANIMAL HUB
      </Box>
    </Box>
  );
}

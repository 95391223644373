'use client';

import { css, cva, cx } from '../styled-system/css';
import { useId, useState } from 'react';

import { Box } from './box';
import { Portal } from './portal';
import { SpriteIconButton } from './sprite-icon-button';
import { SystemStyleObject } from '../styled-system/types';
import { Typography } from './typography';
import { motion } from 'framer-motion';
import { useDebouncedValue } from '../hooks/use-debounced-value';
import { useFreezeScroll } from '../hooks/use-freeze-scroll';

const drawer = cva({
  base: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    zIndex: 'overlayContent',

    '& .drawer-content': {
      background: 'surface.low',
      color: 'surface.text',
      border: '0px solid token(colors.m3.outline)',
      padding: 5,
      width: { base: '100vw', sm: 'calc(75vw)', md: '30rem' },
      height: '100vh',
    },
  },
  defaultVariants: { isOpen: 'closed', side: 'right' },
  variants: {
    isOpen: {
      open: {},
      closed: {},
    },
    side: {
      left: { left: 0, '& .drawer-content': { borderTopRightRadius: 'md', borderBottomRightRadius: 'md' } },
      right: { right: 0, '& .drawer-content': { borderTopLeftRadius: 'md', borderBottomLeftRadius: 'md' } },
    },
  },
});

type Variants = NonNullable<Parameters<typeof drawer>[0]>;

const DRAWER_VIEWPORT_ID = 'drawer-viewport';

export type DrawerProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
  css?: SystemStyleObject;
  defaultOpen?: boolean;
  drawerRef?: React.RefObject<HTMLDivElement>;
  onClose?: () => void;
  scrim?: boolean;
  side: Variants['side'];
  spritePath: string;
  title: string;
  trigger: React.ReactNode;
};

export function Drawer({
  actions,
  children,
  css: cssObject = {},
  defaultOpen = false,
  drawerRef,
  onClose,
  scrim = false,
  side = 'right',
  spritePath = 'sprites/spritesheet.svg',
  title,
  trigger,
}: DrawerProps) {
  const wrapperId = useId();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const isOpenDebounced = useDebouncedValue(isOpen, { millis: 1000 });
  const isRendered = isOpen || isOpenDebounced;
  const motionState = isOpen ? 'open' : 'closed';
  const isLeft = side === 'left';
  const showScrim = useDebouncedValue(scrim && isOpen, { millis: 50 });

  useFreezeScroll(isOpen);

  function toggle() {
    setIsOpen(!isOpen);

    if (isOpen && onClose) {
      onClose();
    }
  }

  return (
    <>
      <Box onClick={toggle}>{trigger}</Box>

      <Portal id={DRAWER_VIEWPORT_ID}>
        {isRendered && (
          <section
            className={cx(
              `drawer-is-${motionState}`,
              `drawer-scrim-${showScrim ? 'show' : 'hide'}`,
              drawer({ side }),
              css({
                '& .wrapper': {
                  display: 'flex',
                  flexDirection: 'column',
                  height: ['calc(100vh - 6rem)', '100%'],

                  '& .content': {
                    flex: 1,
                  },
                },
              })
            )}
            data-side={side}
          >
            <motion.div
              animate={motionState}
              className='drawer-content'
              initial={{ x: isLeft ? 'calc(-100% - 4rem)' : 'calc(100%)' }}
              variants={{ open: { x: 0 }, closed: { x: isLeft ? 'calc(-100% - 4rem)' : 'calc(100%)' } }}
            >
              <div
                className={cx(
                  'wrapper',
                  css({
                    '& .actions': {
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      gap: 4,
                      
                    },
                    ...cssObject,
                  })
                )}
                id={wrapperId}
                ref={drawerRef}
              >
                <SpriteIconButton
                  className='drawer-close-button'
                  css={{ position: 'absolute', top: '24px', right: '16px' }}
                  disabled={!isOpen}
                  name='close'
                  onClick={toggle}
                  path={spritePath}
                  size='xl'
                />
                <Box css={{ paddingY: 3 }}>
                  <Typography type='h5'>{title}</Typography>
                </Box>
                <Box className='content' scroll='subtle'>
                  {children}
                </Box>
                <Box className='actions toggle' onClick={toggle}>
                  {actions}
                </Box>
              </div>
            </motion.div>
          </section>
        )}
      </Portal>
    </>
  );
}

export function DrawerViewport() {
  function closeHighestDrawer() {
    const buttons = document.getElementById(DRAWER_VIEWPORT_ID)?.querySelectorAll('.drawer-close-button');
    const lastButton = buttons && buttons[buttons.length - 1];

    if (lastButton) {
      (lastButton as HTMLElement).click();
    }
  }

  return (
    <Box
      css={{
        '& > div:has(+ section)': {
          background: 'transparent',
          display: 'block',
          transition: 'background 300ms ease-in-out',
        },
        '& > div:has(+ .drawer-is-open)': {},
        '& > div:has(+ .drawer-scrim-show)': { background: 'm3.scrim090' },
      }}
      id={DRAWER_VIEWPORT_ID}
    >
      <Box
        className='drawer-viewport-overlay'
        css={{ display: 'none', position: 'fixed', inset: 0, zIndex: 'overlay' }}
        onClick={closeHighestDrawer}
      />
    </Box>
  );
}

'use client';

import * as RadixAspectRatio from '@radix-ui/react-aspect-ratio';

import { SystemStyleObject } from '../styled-system/types';
import { css } from '../styled-system/css';

type AspectRatioProps = { css?: SystemStyleObject } & RadixAspectRatio.AspectRatioProps;

export function AspectRatio({ css: cssObject = {}, ...props }: AspectRatioProps) {
  return <RadixAspectRatio.Root className={css(cssObject)} {...props} />;
}

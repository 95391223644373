import { useEffect } from 'react';

export function useFreezeScroll(frozen: boolean) {
  useEffect(() => {
    if (frozen) {
      document.documentElement.classList.add('freeze-scroll');
    } else {
      document.documentElement.classList.remove('freeze-scroll');
    }
  }, [frozen]);
}

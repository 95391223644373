'use client';

import { Box } from 'ui/components/box';
import { Icon } from '../../components/icon';
import { Typography } from 'ui/components/typography';
import { useState } from 'react';

export function Faqs() {
  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
        gap: 4,
        '& > div': { display: 'flex', flexDirection: 'column', gap: 6 },
      }}
    >
      <div>
        {FIRST_HALF_FAQS.map(([question, answer], i) => (
          <Faq answer={answer} key={question} question={question} />
        ))}
      </div>
      <div>
        {SECOND_HALF_FAQS.map(([question, answer], i) => (
          <Faq answer={answer} key={question} question={question} />
        ))}
      </div>
    </Box>
  );
}

function Faq({ answer, question, startOpen = false }: { answer: string; question: string; startOpen?: boolean }) {
  const [open, setOpen] = useState(startOpen);

  return (
    <Box
      className={open ? 'open' : 'closed'}
      css={{
        '&.open': {
          '& .question': {
            background: 'esa.secondary',
            borderTopRadius: 'faq',

            '& span': {
              color: 'esa.on_secondary',
            },
          },
          '& .answer': {
            borderBottomRadius: 'faq',
            backgroundColor: 'esa.background',
          },
        },
        '&.closed': {
          borderRadius: 'faq',
          outline: '1px solid token(colors.esa.border)',

          '& .answer': {
            borderBottomRadius: 0,
            backgroundColor: 'esa.background',
          },
        },
      }}
    >
      <Box
        className='question'
        css={{ gap: 6, cursor: 'pointer', padding: 4 }}
        layout='flexRow'
        onClick={() => setOpen((o) => !o)}
      >
        <Typography css={{ flex: 1, fontSize: '22px' }} type='subtitle1'>
          {question}
        </Typography>
        {open ? (
          <Icon css={{ color: 'esa.on_secondary' }} name='remove' size='2xl' />
        ) : (
          <Icon css={{ color: 'type.color' }} name='add' size='2xl' />
        )}
      </Box>

      <Typography
        as='div'
        className='answer'
        css={{
          paddingX: 3,
          paddingY: open ? 3 : 0,
          maxHeight: open ? 'inherit' : '0px',
          overflow: 'hidden',
          transition: 'all 500ms ease',
        }}
        type='body2'
      >
        {answer}
      </Typography>
    </Box>
  );
}

const FAQS = [
  [
    'What is an Emotional Support Animal (ESA)',
    "An ESA is a pet that provides emotional and psychological support to individuals suffering from emotional issues, anxiety, or psychiatric problems. Unlike service animals, they don't require specific training to perform tasks.",
  ],
  [
    'What types of animals can be ESAs?',
    'Most ESAs are dogs or cats, but theoretically, any animal that provides comfort and support can be an ESA. This includes smaller animals like rabbits or even birds.',
  ],
  [
    'Do ESAs have legal rights?',
    "ESAs are protected under the Fair Housing Act, which allows them to live in housing with their owners regardless of pet policies. However, they don't have the same public access rights as service animals.",
  ],
  [
    'Do landlords have to accept ESAs?',
    'Yes, under the Fair Housing Act, landlords must reasonably accommodate ESAs, even in buildings with a no-pet policy. However, there are exceptions, such as when the animal poses a direct threat to the safety of others or causes substantial property damage.',
  ],
  [
    'Can airlines refuse ESAs?',
    'As of recent changes, airlines are not required to accommodate ESAs as they do with service animals. Airlines can treat ESAs as pets, which might require them to be in a carrier or pay a pet fee.',
  ],
  [
    'Do I need to register my ESA?',
    'There is no official registry for ESAs. Registration services are often misleading and not recognized by legal entities.',
  ],
  [
    'How is an ESA different from a service animal?',
    'Service animals are trained to perform specific tasks for individuals with disabilities, such as guiding a blind person or alerting a deaf person. ESAs provide comfort through their presence and do not require specialized training.',
  ],
  [
    'Can ESAs go anywhere with me like service animals?',
    'No, ESAs do not have the same public access rights as service animals. They are not allowed in all public spaces, especially where pets are generally not allowed.',
  ],
  [
    'How can I get my pet designated as an ESA?',
    'An ESA must be prescribed by a licensed mental health professional who believes the animal is vital for your mental health.',
  ],

  [
    'What documentation is needed for an ESA?',
    'The primary document needed is a letter from a licensed mental health professional stating the need for an ESA.',
  ],
  [
    'Can schools or workplaces refuse an ESA?',
    'It depends on the policies of the specific institution. Unlike service animals, there are no federal laws that mandate the acceptance of ESAs in these environments.',
  ],
  [
    'Can an ESA be any size or breed?',
    'Yes, there are no restrictions on the size or breed for an ESA. However, the animal should be manageable in public settings and not cause safety concerns.',
  ],

  [
    'How do State Governments regulate ESAs',
    `
    - Housing: While all states must adhere to the FHA, some may have additional laws offering further protection for ESAs in housing.
    - Employment: There is no federal law that specifically requires employers to allow ESAs in the workplace. States may have their own laws or guidelines regarding ESAs in employment settings.
    - Public Access: ESAs do not have the same public access rights as service animals under the Americans with Disabilities Act (ADA). States may have laws that grant ESAs more access rights, but this varies widely.
  `,
  ],
  [
    'What are some limitations of ESAs?',
    `
    - Some states may have specific definitions or requirements for ESAs.
    - Certain types of housing, such as buildings with fewer than a certain number of units where the landlord resides, might be exempt from ESA accommodations under state laws.
    - State laws might also address the issue of fraudulent representation of pets as ESAs.
  `,
  ],

  [
    'Do local ordinances apply to ESAs?',
    'In addition to state laws, local government ordinances can also play a role in the regulation of ESAs, especially regarding where they can be taken and how they must be handled.',
  ],
  [
    'Do states have laws regarding misrepresentation of ESAs?',
    `
    Most state laws focus on misrepresentation of Service Animals rather than Emotional Support Animals. Consult a lawyer for specific legal advice. 

    Our ESA letters are legally recognized for ESAs, not service animals.
    `,
  ],
  [
    'How does the Federal Government regulate ESAs?',
    ` 
     - The Fair Housing Act (FHA) requires housing providers to make reasonable accommodations for ESAs, even in properties with no-pet policies. This is consistent across all states.
     - The Air Carrier Access Act (ACAA), which previously allowed ESAs to travel in the cabin of aircraft without a pet fee, was revised. Airlines are not required to accommodate ESAs like service animals, but this could vary based on airline policies.
    `,
  ],
];

const FIRST_HALF_FAQS = FAQS.slice(0, 9);
const SECOND_HALF_FAQS = FAQS.slice(9);

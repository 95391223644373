import(/* webpackMode: "eager", webpackExports: ["LightCTA","DarkCTA"] */ "/vercel/path0/apps/esa/src/app/_components/cta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faqs"] */ "/vercel/path0/apps/esa/src/app/_components/faqs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logotype"] */ "/vercel/path0/apps/esa/src/app/_components/logotype.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/_components/footer.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"700\",\"400\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/vercel/path0/packages/ui/radix-ui/aspect-ratio.tsx");

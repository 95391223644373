'use client';

import { LOCALFORAGE_KEY_QUIZZES, LOCALFORAGE_KEY_QUIZ_ACTIVE, Quiz, SUBMIT_BUTTON_ID, quizInitialState } from './quiz';

import { Button } from 'ui/components/button';
import { Drawer } from 'ui/components/drawer';
import { Icon } from '../../components/icon';
import { QuizState } from '../providers/schemas';
import { useLocalforage } from 'ui/hooks/use-localforage';
import { Box } from 'ui/components/box';

type CtaProps = {
  defaultOpen?: boolean;
};

export function LightCTA(props: CtaProps) {
  return (
    <DrawerCTA {...props}>
      <Button
        css={{
          background: 'esa.ivory !important',
          color: 'esa.primary !important',
        }}
        iconAfter={<Icon name='arrow_circle_right' size='2xl' />}
        size='lg'
        variant='filled'
      >
        Start the quiz
      </Button>
    </DrawerCTA>
  );
}

export function DarkCTA(props: CtaProps) {
  return (
    <DrawerCTA {...props}>
      <Button iconAfter={<Icon name='arrow_circle_right' size='2xl' />} size='lg' variant='filled'>
        Start the quiz
      </Button>
    </DrawerCTA>
  );
}

export function DrawerCTA({
  children,
  quizId,
  ...drawerProps
}: { children: React.ReactNode; quizId?: string } & CtaProps) {
  const {
    values: [quizzes, quizActive],
  } = useLocalforage<[QuizState[], string]>([LOCALFORAGE_KEY_QUIZZES, LOCALFORAGE_KEY_QUIZ_ACTIVE]);
  const quizState = (quizzes || []).find((q) => q.id === quizActive) ?? quizInitialState;

  return (
    <Drawer
      actions={
        <>
          <Box
            css={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 3,
              paddingBottom: 4,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              disabled={!quizState.isValid}
              onClick={() => document.getElementById(SUBMIT_BUTTON_ID)?.click()}
              type='submit'
              variant='filled'
            >
              Submit
            </Button>
          </Box>
          {/* <Button>Cancel</Button> */}
        </>
      }
      scrim={true}
      side='right'
      spritePath='/sprites/spritesheet.svg'
      title='ESA Quiz'
      trigger={children}
      {...drawerProps}
    >
      <Quiz quizId={quizId} />
    </Drawer>
  );
}
